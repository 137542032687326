import React, { useEffect } from 'react';

const ScrollBlocker: React.FC = () => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'scroll';
    };
  });
  return <></>;
};

export default ScrollBlocker;

import { Typography, Grid, Container } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import { MarkdownRemarkFrontmatterSections } from '../../types/graphqlTypes';

interface ILeaderProps {
  section: MarkdownRemarkFrontmatterSections;
}

export const Leader: React.FunctionComponent<ILeaderProps> = ({ section }) => {
  if (!section || !section.componentId || !section.componentId.frontmatter) {
    return <p>content invalid</p>;
  }
  const { frontmatter } = section.componentId;

  return (
    <LeaderContainer maxWidth={false}>
      <Grid container={true}>
        <Textfields item={true} xs={8} xl={12}>
          <Typography variant='h1'>{frontmatter.leaderText}</Typography>
          <Typography variant='subtitle1'>{frontmatter.subtext}</Typography>
        </Textfields>
      </Grid>
    </LeaderContainer>
  );
};

const LeaderContainer = withStyles(theme => ({
  root: {
    height: '550px',
    paddingTop: theme.spacing(6),
    paddingLeft: theme.spacing(10),
    background:
      'url(images/assets/bushes.png) left 0 bottom no-repeat, url(images/assets/house.png) right 0 bottom no-repeat',
    backgroundSize: 'auto 50%, contain',
    [theme.breakpoints.down('xl')]: {
      backgroundPosition: 'left -20px bottom, right -50px bottom',
    },
    [theme.breakpoints.down('md')]: {
      backgroundPosition: 'left -200px bottom, right -200px bottom',
      height: '400px',
      paddingTop: '40px',
      paddingLeft: '7%',
    },
    [theme.breakpoints.down('xs')]: {
      backgroundPosition: 'left -70px bottom, right -100px bottom',
      height: '200px',
      paddingTop: '10px',
      paddingLeft: '10%',
    },
  },
}))(Container);

const Textfields = withStyles(theme => ({
  root: {
    textAlign: 'left',
    [theme.breakpoints.up('xl')]: {
      textAlign: 'center',
    },
  },
}))(Grid);

import {
  CircularProgress,
  TextField,
  InputProps as InputPropsType,
  InputAdornment,
} from '@material-ui/core';
import React from 'react';
import { Control, FieldErrors, useController } from 'react-hook-form';

interface IControlledInput {
  name: string;
  label: string;
  control: Control<Record<string, string>>;
  errors?: FieldErrors; // todo: correctly type
  disabled?: boolean;
  rules?: any;
  autoComplete?: any;
  type?: any;
  placeholder?: string;
  InputProps?: InputPropsType;
  helperText?: string;
  loading?: boolean;
}

const LoadingAdornment = (
  <InputAdornment position='start'>
    <CircularProgress />{' '}
  </InputAdornment>
);

export const ControlledInput: React.FunctionComponent<IControlledInput> = ({
  name,
  label,
  helperText,
  control,
  disabled,
  InputProps,
  placeholder,
  rules,
  autoComplete,
  loading,
}) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, error },
  } = useController({
    control,
    name: name as `${string}`,
    defaultValue: '',
    rules,
  });

  const errorText = invalid ? error?.message : '';
  return (
    <TextField
      inputRef={ref}
      name={name}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      label={label}
      placeholder={placeholder}
      fullWidth={true}
      variant='outlined'
      helperText={errorText || helperText}
      error={!!errorText}
      disabled={disabled}
      autoComplete={autoComplete}
      InputProps={
        loading
          ? {
              ...InputProps,
              startAdornment: LoadingAdornment,
            }
          : InputProps
      }
    />
  );
};

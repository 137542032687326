import {
  Grid,
  ThemeProvider as MaterialThemeProvider,
} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

// import GlobalStyles from './globalStyles';
import { theme } from './globalStyles/styles';

export const Layout: FC = ({ children }) => (
  <>
    <Helmet>
      <link rel='preconnect' href='https://fonts.gstatic.com' />
      <link
        rel='preload'
        href='https://fonts.googleapis.com/css2?family=Rubik:wght@300;900&display=swap'
        as='style'
        onLoad="this.onload=null;this.rel='stylesheet'"
      />
      <noscript>
        {`<link
          href='https://fonts.googleapis.com/css2?family=Rubik:wght@300;900&display=swap'
          rel='stylesheet'
          type='text/css'
        />`}
      </noscript>
    </Helmet>
    <CssBaseline />
    <MaterialThemeProvider theme={theme}>
      <Grid
        justify='center'
        alignItems='center'
        direction='column'
        container={true}
      >
        {children}
      </Grid>
    </MaterialThemeProvider>
  </>
);

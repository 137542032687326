import { Container, Grid, Link, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';

import { MarkdownRemarkFrontmatterSections } from '../../types/graphqlTypes';

interface IContactProps {
  section: MarkdownRemarkFrontmatterSections;
}

const Contact: React.FunctionComponent<IContactProps> = ({ section }) => {
  if (!section || !section.componentId || !section.componentId.frontmatter) {
    return <p>content invalid</p>;
  }

  const { frontmatter } = section.componentId;

  return (
    <BlueBackground>
      <Container maxWidth='lg' style={{ padding: '80px 0' }}>
        <Grid container={true} justify='center'>
          <LogoContainer xs={10} item={true}>
            <img
              src='images/assets/logo_white.svg'
              alt='dehuizenmarkt.nl logo'
            />
          </LogoContainer>
          <Grid item={true} xs={10} sm={5}>
            <Typography variant='h3' style={{ color: '#fff' }}>
              {frontmatter.title}
            </Typography>
            <Typography variant='body1' style={{ color: '#fff' }}>
              {frontmatter.text}
            </Typography>
          </Grid>
          <Grid item={true} xs={10} sm={5}>
            {frontmatter.contactList?.map((item, index) => {
              if (!item) return <></>;
              return (
                <FooterItem variant='body1' key={`footeritem-${index}`}>
                  {item.link ? (
                    <Link
                      href={item.link}
                      variant='body1'
                      style={{ color: '#fff' }}
                    >
                      {item?.text}
                    </Link>
                  ) : (
                    item.text
                  )}
                </FooterItem>
              );
            })}
          </Grid>
        </Grid>
      </Container>
    </BlueBackground>
  );
};

export default Contact;

const FooterItem = withStyles(theme => ({
  root: {
    color: '#fff',
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
}))(Typography);
const LogoContainer = withStyles(theme => ({
  root: {
    marginBottom: theme.spacing(4),
  },
}))(Grid);

const BlueBackground = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
  },
}))(Grid);

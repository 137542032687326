import {
  InputProps as InputPropsType,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import React from 'react';
import { Control, FieldErrors, useController } from 'react-hook-form';

interface IControlledCheckbox {
  name: string;
  label: string;
  control: Control<Record<string, string>>;
  errors?: FieldErrors; // todo: correctly type
  disabled?: boolean;
  rules?: any;
  autoComplete?: any;
  type?: any;
  placeholder?: string;
  InputProps?: InputPropsType;
}

export const ControlledCheckbox: React.FunctionComponent<IControlledCheckbox> = ({
  name,
  label,
  control,
  rules,
}) => {
  const {
    field: { onChange, onBlur, value, ref },
  } = useController({
    control,
    name: name as `${string}`,
    defaultValue: false,
    rules,
  });

  return (
    <FormControlLabel
      inputRef={ref}
      control={
        <Checkbox
          name={name}
          checked={value}
          onBlur={onBlur}
          onChange={onChange}
        />
      }
      label={label}
    />
  );
};

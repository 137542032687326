import {
  Button,
  Container,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useState } from 'react';

import OfferRequestForm from '../../components/OfferRequestForm/OfferRequestForm';
import { MarkdownRemarkFrontmatterSections } from '../../types/graphqlTypes';

interface IStepsBlockProps {
  section: MarkdownRemarkFrontmatterSections;
}

const StepsBlock: React.FunctionComponent<IStepsBlockProps> = ({ section }) => {
  const [showOfferRequestForm, setShowOfferRequestForm] =
    useState<boolean>(false);
  const classes = useStyles();
  if (
    !section ||
    !section.componentId ||
    !section.componentId.frontmatter ||
    !section.componentId.frontmatter.blocks ||
    !section.componentId.frontmatter.blocks.length
  ) {
    return <p>no data</p>;
  }
  const { blocks } = section.componentId.frontmatter;
  return (
    <>
      <BackgroundColor justify='center' container={true}>
        <StepsContainer maxWidth='lg'>
          <Grid container={true} justify='center'>
            <Grid
              item={true}
              xs={12}
              spacing={6}
              container={true}
              justify='center'
            >
              <Header variant='h2'>
                {section.componentId.frontmatter.title}
              </Header>
            </Grid>
            <Grid
              xs={12}
              md={10}
              justify='center'
              container={true}
              item={true}
              style={{ boxSizing: 'border-box' }}
            >
              {blocks.map(
                (block, index) =>
                  block && (
                    <Grid
                      item={true}
                      container={true}
                      direction='row'
                      key={index}
                      xs={12}
                      sm={10}
                      md={8}
                    >
                      <BulletPoint
                        item={true}
                        container={true}
                        xs={4}
                        justify='flex-end'
                        alignItems='center'
                        alignContent='center'
                      >
                        {block.image && (
                          <ImageContainer
                            item={true}
                            alignItems='center'
                            container={true}
                          >
                            <img
                              src={block.image}
                              className={classes.icon}
                              alt={block.title || 'dehuizenmarkt usp icon'}
                            />
                          </ImageContainer>
                        )}
                      </BulletPoint>
                      <Grid
                        item={true}
                        xs={8}
                        alignContent='center'
                        container={true}
                      >
                        <BlockText variant='body1'>{block.text}</BlockText>
                      </Grid>
                    </Grid>
                  ),
              )}
            </Grid>
            <Grid
              item={true}
              container={true}
              xs={12}
              justify='center'
              style={{ padding: '40px 0', boxSizing: 'border-box' }}
            >
              <Button
                variant='contained'
                color='secondary'
                onClick={() => setShowOfferRequestForm(true)}
              >
                {section.componentId.frontmatter.buttonText}
              </Button>
            </Grid>
          </Grid>
        </StepsContainer>
      </BackgroundColor>
      {showOfferRequestForm && (
        <OfferRequestForm
          postalCode=''
          houseNumber=''
          setShowOfferRequestForm={setShowOfferRequestForm}
        />
      )}
    </>
  );
};

export default StepsBlock;

const useStyles = makeStyles(() => ({
  icon: {
    width: '100%',
    marginRight: '15px',
  },
}));

const ImageContainer = withStyles(theme => ({
  root: {
    width: 'auto',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '40%',
    },
  },
}))(Grid);

const BulletPoint = withStyles(theme => ({
  root: {
    background:
      'url(images/assets/line.svg) top 50% left 30px repeat-y, url(images/assets/bulletpoint.svg) top 50% left 10px no-repeat',
    flexBasis: '200px',
    textAlign: 'right',
    boxSizing: 'border-box',
    padding: '15px',
    [theme.breakpoints.down('xs')]: {
      backgroundPosition: 'top 50% left 30px, top 50% left 15px',
      backgroundSize: '10px, 40px ',
      flexBasis: '150px',
    },
  },
}))(Grid);

const BlockText = withStyles(() => ({
  root: {
    color: 'white',
  },
}))(Typography);

const StepsContainer = withStyles(theme => ({
  root: {
    margin: theme.spacing(6, 0),
    boxSizing: 'border-box',
  },
}))(Container);

const BackgroundColor = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(13, 0),
    width: '100%',
  },
}))(Grid);

const Header = withStyles(theme => ({
  root: {
    color: 'white',
    textAlign: 'center',
    marginBottom: theme.spacing(6),
  },
}))(Typography);

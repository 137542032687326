import {
  Container,
  Grid,
  makeStyles,
  Typography,
  withStyles,
} from '@material-ui/core';
import * as React from 'react';

import { MarkdownRemarkFrontmatterSections } from '../../types/graphqlTypes';

export interface IUspBlockProps {
  section: MarkdownRemarkFrontmatterSections;
}

export const UspBlock: React.FunctionComponent<IUspBlockProps> = ({
  section,
}) => {
  if (
    !section ||
    !section.componentId ||
    !section.componentId.frontmatter ||
    !section.componentId.frontmatter.blocks ||
    !section.componentId.frontmatter.blocks.length
  ) {
    return <p>no data</p>;
  }
  const { blocks } = section.componentId.frontmatter;

  const classes = useStyles();

  return (
    <UspContainer maxWidth='lg'>
      <Title variant='h2'>{section.componentId.frontmatter.title}</Title>
      <Grid container={true} justify='center' spacing={1} xs={12} item={true}>
        {blocks.map(
          block =>
            block && (
              <Block
                key={block.title}
                item={true}
                container={true}
                xs={6}
                spacing={1}
                direction='row'
                justify='center'
                alignContent='flex-start'
              >
                <ImgContainer item={true} xs={8}>
                  {block.image && (
                    <img
                      className={classes.img}
                      src={block.image}
                      alt={block.title ?? 'usp icon'}
                    />
                  )}
                </ImgContainer>
                <Grid container={true} direction='column' xs={8} item={true}>
                  <BlockTitle variant='h3'>{block.title}</BlockTitle>
                  <BlockText variant='body1'>{block.text}</BlockText>
                </Grid>
              </Block>
            ),
        )}
      </Grid>
    </UspContainer>
  );
};

const useStyles = makeStyles({
  img: {
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
  },
});

const UspContainer = withStyles(theme => ({
  root: {
    margin: `${theme.spacing(13, 0)}`,
  },
}))(Container);

const Block = withStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
}))(Grid);

const Title = withStyles(theme => ({
  root: {
    textAlign: 'center',
    paddingBottom: theme.spacing(6),
  },
}))(Typography);

const ImgContainer = withStyles(() => ({
  root: {
    textAlign: 'center',
    height: '100px',
  },
}))(Grid);

const BlockTitle = withStyles(() => ({
  root: {
    margin: '13px 0',
    textAlign: 'center',
  },
}))(Typography);

const BlockText = withStyles(() => ({
  root: {
    textAlign: 'center',
  },
}))(Typography);

import * as React from 'react';

import Contact from '../blocks/Contact/Contact';
import { Header } from '../blocks/Header/Header';
import { Leader } from '../blocks/Leader/Leader';
import Reviews from '../blocks/Reviews/Reviews';
import { SignupForm } from '../blocks/SignupForm/SignupForm';
import StepsBlock from '../blocks/StepsBlock/StepsBlock';
import { UspBlock } from '../blocks/UspBlock/UspBlock';
import { MarkdownRemarkFrontmatterSections } from '../types/graphqlTypes';

interface IComponentsProps {
  sections: Array<MarkdownRemarkFrontmatterSections>;
}

const componentsRegistry = {
  header: Header,
  leader: Leader,
  uspBlock: UspBlock,
  stepsBlock: StepsBlock,
  reviews: Reviews,
  contact: Contact,
  signupForm: SignupForm,
};

const Components: React.FunctionComponent<IComponentsProps> = ({
  sections,
}) => {
  const sectionComponents = sections.map(section => {
    if (
      !section ||
      !section.componentId ||
      !section.componentId.frontmatter ||
      !section.componentId.frontmatter.type
    ) {
      return null;
    }
    const Component =
      componentsRegistry[
        section.componentId.frontmatter.type as keyof typeof componentsRegistry
      ];

    if (Component) {
      return (
        <Component
          section={section}
          key={`component-${section.componentId.frontmatter.componentId}`}
        />
      );
    }
    return null;
  });
  return <>{sectionComponents}</>;
};

export default Components;

import { Grid, makeStyles, withStyles } from '@material-ui/core';
import React from 'react';

import dhmLogo from '../../../static/images/assets/dhm_logo.svg';

interface IHeaderProps {}

const LogoContainer = withStyles(theme => ({
  root: {
    margin: theme.spacing(4, 0),
    maxWidth: `${theme.breakpoints.values.lg}px`,
  },
}))(Grid);

export const Header: React.FunctionComponent<IHeaderProps> = () => {
  const classes = useStyles();

  return (
    <LogoContainer justify='center' alignItems='center' container={true}>
      <img src={dhmLogo} className={classes.img} alt='dehuizenmarkt.nl logo' />
    </LogoContainer>
  );
};

const useStyles = makeStyles(theme => ({
  img: {
    [theme.breakpoints.down('xs')]: {
      width: '150px',
    },
  },
}));

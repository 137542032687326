import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import * as React from 'react';

import { MarkdownRemarkFrontmatterSections } from '../../types/graphqlTypes';

interface IReviewsProps {
  section: MarkdownRemarkFrontmatterSections;
}

const Reviews: React.FunctionComponent<IReviewsProps> = ({ section }) => {
  const classes = useStyles();

  if (
    !section ||
    !section.componentId ||
    !section.componentId.frontmatter ||
    !section.componentId.frontmatter.reviews ||
    !section.componentId.frontmatter.reviews.length
  ) {
    return <p>no data</p>;
  }
  const { frontmatter } = section.componentId;

  return (
    <ReviewContainer maxWidth='lg'>
      <ReviewHeader item={true}>
        <Title variant='h2'>{frontmatter.title}</Title>
        <Subtext variant='body1'>{frontmatter.subtext}</Subtext>
      </ReviewHeader>

      <Grid container={true} justify='space-around' spacing={2}>
        {frontmatter.reviews.map((review, index) => (
          <Grid xs={10} md={5} item={true} key={`review-${index}`}>
            <Review style={{ backgroundColor: '#f6f6f6' }}>
              <Typography>{review?.text}</Typography>
              <Grid container={true}>
                <img
                  className={classes.img}
                  src={review?.avatar}
                  alt='dehuizenmarkt.nl reviewer'
                />
                <Typography>{review?.reviewerName}</Typography>
              </Grid>
            </Review>
          </Grid>
        ))}
      </Grid>
    </ReviewContainer>
  );
};

export default Reviews;

const ReviewHeader = withStyles(theme => ({
  root: {
    marginBottom: theme.spacing(6),
  },
}))(Grid);

const ReviewContainer = withStyles(theme => ({
  root: {
    margin: theme.spacing(13, 0),
  },
}))(Container);

const Title = withStyles(() => ({
  root: {
    textAlign: 'center',
  },
}))(Typography);

const Subtext = withStyles(() => ({
  root: {
    textAlign: 'center',
  },
}))(Typography);

const Review = withStyles(() => ({
  root: {
    padding: '22px',
  },
}))(Grid);

const useStyles = makeStyles(theme => ({
  img: {
    width: '32px',
    height: '32px',
    borderRadius: '16px',
    marginRight: theme.spacing(1),
  },
}));

import {
  Container,
  Grid,
  Typography,
  Button,
  MenuItem,
  SvgIcon,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm, Control, useWatch } from 'react-hook-form';

import { ControlledCheckbox } from '../ControlledCheckbox/ControlledCheckbox';
import { ControlledInput } from '../ControlledInput/ControlledInput';
import { ControlledSelect } from '../ControlledSelect/ControlledSelect';
import ScrollBlocker from '../ScrollBlocker/ScrollBlocker';

interface IOfferRequestFormProps {
  postalCode: string;
  houseNumber: string;
  setShowOfferRequestForm: (input: boolean) => input;
  addition?: string;
  hidden?: boolean;
}

const FormStepper = ({
  step,
  control,
  disabledAddressField,
  loading,
}: {
  step: number;
  control: Control;
  disabledAddressField: boolean;
  loading: boolean;
}) => {
  switch (step) {
    case 0:
      return (
        <AddressForm
          control={control}
          disabledAddressField={disabledAddressField}
          loading={loading}
        />
      );
    case 1:
      return <PropertyPropertiesForm control={control} />;
    case 2:
      return <ContactForm control={control} />;
    default:
      return <p>no step</p>;
  }
};

const OfferRequestForm: React.FunctionComponent<IOfferRequestFormProps> = ({
  postalCode,
  houseNumber,
  addition,
  hidden,
  setShowOfferRequestForm,
}) => {
  const { handleSubmit, control, setValue, setError, trigger } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      houseSqm: '',
      landSqm: '',
      condition: '',
      street: '',
      city: '',
      postalCode: '',
      houseNumber: '',
      addition: '',
      garden: false,
      roofTerrace: false,
      balcony: false,
    },
    mode: 'onTouched',
    shouldUnregister: false,
  });

  const [postalCodeField, houseNumberField, additionField] = useWatch({
    name: ['postalCode', 'houseNumber', 'addition'],
    control,
  });

  const [disabledAddressField, setDisabledAddressField] =
    useState<boolean>(true);

  const [activeStep, setActiveStep] = useState<number>(0);
  const steps = ['Step1', 'Step2', 'Step3'];
  const stepFields = [
    ['postalCode', 'houseNumber', 'street', 'city'],
    ['condition', 'houseSqm', 'garden', 'roofTerrace', 'balcony', 'landSqm'],
    ['firstName', 'lastName', 'phone', 'email'],
  ];
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setValue('postalCode', postalCode);
    setValue('houseNumber', houseNumber);
    setValue('addition', addition ?? '');
  }, [postalCode, houseNumber, addition]);

  const findAddress = useCallback(
    debounce((postalCodeField, houseNumberField, additionField) => {
      setLoading(true);
      fetch('/.netlify/functions/postcode', {
        method: 'POST',
        body: JSON.stringify({
          postalCode: postalCodeField,
          houseNumber: houseNumberField,
          addition: additionField,
        }),
      })
        .then(res => {
          if (res.status !== 200) {
            // TODO: manually add city and street
            setError('postalCode', {
              type: 'manual',
              message: 'Postcode niet gevonden',
            });
            throw new Error('postalcode not found');
          }
          return res.json();
        })
        .then(res => {
          setValue('street', res[0].straatnaam);
          setValue('city', res[0].woonplaatsnaam);
          setLoading(false);
        })
        .catch(() => {
          setDisabledAddressField(false);
          setLoading(false);
        });
    }, 500),
    [],
  );
  useEffect(() => {
    if (!postalCodeField || !houseNumberField) {
      return;
    }
    findAddress(postalCodeField, houseNumberField, additionField);
  }, [postalCodeField, houseNumberField, additionField]);

  const onSubmit = (data: any) => {
    console.log(data);
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        'form-name': 'estimation_request',
        ...data,
      }).toString(),
    })
      .then(() => handleNext())
      .catch(error => {
        console.log(error);
      });
  };

  const handleNext = async () => {
    const isStepValid = await trigger(stepFields[activeStep]);

    if (isStepValid) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  if (hidden) {
    return (
      <form
        action='/'
        data-netlify='true'
        hidden={hidden}
        name='estimation_request'
      >
        <PropertyPropertiesForm control={control} />
        <ContactForm control={control} />
        <AddressForm control={control} />
      </form>
    );
  }

  return (
    <>
      <FormPopup>
        <StyledCloseIcon
          component={CloseIcon}
          onClick={() => setShowOfferRequestForm(false)}
        />
        <Container maxWidth='md'>
          {activeStep === steps.length ? (
            <Grid item={true} justify='center'>
              <Typography variant='h1'>Bedankt voor je aanvraag!</Typography>
              <Typography variant='subtitle1'>
                Je ontvangt zo spoedig mogelijk een aanbod van ons.
              </Typography>
            </Grid>
          ) : (
            <>
              <Grid item={true} xs={12}>
                <form
                  action='/'
                  data-netlify='true'
                  hidden={hidden}
                  name='estimation_request'
                >
                  <FormTitle variant='h1'>
                    ontvang vrijblijvend een indicatief bod op uw woning
                  </FormTitle>
                  <FormStepper
                    step={activeStep}
                    control={control}
                    disabledAddressField={disabledAddressField}
                    loading={loading}
                  />
                  <input
                    type='hidden'
                    name='form-name'
                    value='estimation_request'
                  />
                </form>
              </Grid>
              <ButtonGrid item={true} xs={12} container={true} justify='center'>
                <Button disabled={activeStep === 0} onClick={handleBack}>
                  Terug
                </Button>
                {activeStep === steps.length - 1 ? (
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleSubmit(onSubmit)}
                  >
                    Verstuur
                  </Button>
                ) : (
                  <Button
                    color='primary'
                    variant='contained'
                    disableElevation={true}
                    size='large'
                    type='button'
                    onClick={handleNext}
                  >
                    volgende
                  </Button>
                )}
              </ButtonGrid>
            </>
          )}
        </Container>
      </FormPopup>
      <ScrollBlocker />
    </>
  );
};

export default OfferRequestForm;

const ButtonGrid = withStyles(theme => ({
  root: {
    marginTop: theme.spacing(5),
  },
}))(Grid);

const FormTitle = withStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1),
  },
}))(Typography);

interface ControlledFormPart {
  control: Control;
}

const PropertyPropertiesForm: React.FunctionComponent<ControlledFormPart> = ({
  control,
}) => (
  <Grid spacing={2} container={true}>
    <Grid item={true} xs={6}>
      <ControlledSelect
        name='condition'
        control={control}
        label='staat'
        placeholder='staat'
        rules={{ required: 'Huidige staat is vereist' }}
      >
        <MenuItem value='opknapper'>Slecht</MenuItem>
        <MenuItem value='medium'>medium</MenuItem>
        <MenuItem value='uitstekend'>uitstekend</MenuItem>
      </ControlledSelect>
    </Grid>
    <Grid item={true} xs={6}>
      <ControlledInput
        name='houseSqm'
        label='M2 woning'
        control={control}
        type='number'
        placeholder='m2'
        rules={{ required: 'Oppervlakte is vereist' }}
        helperText='Vul hier het aantal vierkante meters van uw woning in.'
      />
    </Grid>
    <Grid item={true} xs={6}>
      <ControlledCheckbox name='garden' control={control} label='Tuin' />
      <ControlledCheckbox
        name='roofTerrace'
        control={control}
        label='Dak terras'
      />
      <ControlledCheckbox name='balcony' control={control} label='Balkon' />
    </Grid>
    <Grid item={true} xs={6}>
      <ControlledInput
        name='landSqm'
        label='M2 Tuin'
        control={control}
        type='number'
        placeholder='m2'
      />
    </Grid>
  </Grid>
);

const ContactForm: React.FunctionComponent<ControlledFormPart> = ({
  control,
}) => (
  <Grid container={true} spacing={2}>
    <Grid item={true} xs={6}>
      <ControlledInput name='firstName' label='Voornaam' control={control} />
    </Grid>
    <Grid item={true} xs={6}>
      <ControlledInput
        name='lastName'
        label='Achternaam'
        autoComplete='last name'
        control={control}
        rules={{ required: 'Achternaam is vereist' }}
      />
    </Grid>
    <Grid item={true} xs={6}>
      <ControlledInput
        name='phone'
        label='Phone'
        autoComplete='phone'
        control={control}
      />
    </Grid>
    <Grid item={true} xs={6}>
      <ControlledInput
        name='email'
        label='Email'
        autoComplete='email'
        control={control}
        rules={{
          required: 'Email is vereist',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Ongeldig emailadres',
          },
        }}
      />
    </Grid>
  </Grid>
);

interface IAddressForm extends ControlledFormPart {
  disabledAddressField: boolean;
  loading?: boolean;
}

const AddressForm: React.FunctionComponent<IAddressForm> = ({
  control,
  disabledAddressField,
  loading,
}) => (
  <Grid container={true} spacing={2}>
    <Grid item={true} xs={6}>
      <ControlledInput
        name='postalCode'
        label='Postcode'
        control={control}
        rules={{ required: 'Postcode is vereist' }}
      />
    </Grid>
    <Grid item={true} xs={6}>
      <ControlledInput
        name='houseNumber'
        label='Huisnummer'
        control={control}
        rules={{ required: 'Huisnummer is vereist' }}
      />
    </Grid>
    <Grid item={true} xs={6}>
      <ControlledInput
        name='street'
        label='Straat'
        control={control}
        disabled={disabledAddressField}
        rules={{ required: 'Straatnaam is vereist' }}
        loading={loading}
      />
    </Grid>
    <Grid item={true} xs={6}>
      <ControlledInput
        name='city'
        label='Stad'
        control={control}
        disabled={disabledAddressField}
        rules={{ required: 'Stad is vereist' }}
        loading={loading}
      />
    </Grid>
  </Grid>
);

const StyledCloseIcon = withStyles(theme => ({
  root: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))(SvgIcon);

const FormPopup = withStyles(() => ({
  root: {
    width: '90vw',
    height: '90vh',
    background: '#ffffff',
    opacity: '0.97',
    position: 'fixed',
    left: '5vw',
    top: '5vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    overflow: 'scroll',
    filter: 'drop-shadow(0px 5px 50px rgba(24, 65, 109, 0.199046))',
  },
}))(Grid);

import {
  InputProps as InputPropsType,
  Select,
  FormHelperText,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import React from 'react';
import { Control, FieldErrors, useController } from 'react-hook-form';

interface IControlledSelect {
  name: string;
  label: string;
  control: Control<Record<string, string>>;
  errors?: FieldErrors; // todo: correctly type
  disabled?: boolean;
  rules?: any;
  autoComplete?: any;
  type?: any;
  placeholder?: string;
  InputProps?: InputPropsType;
  helperText?: string;
}

export const ControlledSelect: React.FunctionComponent<IControlledSelect> = ({
  name,
  label,
  control,
  placeholder,
  rules,
  children,
  helperText,
}) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, error },
  } = useController({
    control,
    name: name as `${string}`,
    defaultValue: 'staat',
    rules,
  });

  const errorText = invalid ? error?.message : '';

  return (
    <FormControl style={{ width: '100%' }}>
      <InputLabel variant='outlined'>{label}</InputLabel>
      <Select
        inputRef={ref}
        name={name}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        label={label}
        placeholder={placeholder}
        fullWidth={true}
        variant='outlined'
        error={!!errorText}
      >
        {children}
      </Select>
      <FormHelperText error={!!errorText}>
        {errorText || helperText}
      </FormHelperText>
    </FormControl>
  );
};

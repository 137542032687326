import { Typography, Button, Grid, Container } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { ControlledInput } from '../../components/ControlledInput/ControlledInput';
import OfferRequestForm from '../../components/OfferRequestForm/OfferRequestForm';

interface ILeaderProps {
  section: any;
}

export const SignupForm: React.FunctionComponent<ILeaderProps> = ({
  section,
}) => {
  const [showOfferRequestForm, setShowOfferRequestForm] =
    useState<boolean>(false);
  const [postalCode, setPostalCode] = useState<string>('');
  const [houseNumber, setHouseNumber] = useState<string>('');
  const [addition, setAddition] = useState<string>('');

  const { handleSubmit, control } = useForm({
    defaultValues: {
      postalCode: '',
      houseNumber: '',
      addition: '',
    },
  });

  const onSubmit = data => {
    setPostalCode(data.postalCode);
    setHouseNumber(data.houseNumber);
    setAddition(data.addition);
    setShowOfferRequestForm(true);
  };
  const { frontmatter } = section.componentId;
  return (
    <Container maxWidth='lg'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RequestFormContainer container={true} spacing={2} justify='center'>
          <Grid
            item={true}
            xs={12}
            sm={10}
            justify='center'
            container={true}
            spacing={1}
          >
            <SignupText item={true} xs={12} justify='center' container={true}>
              <Typography variant='body1'>{frontmatter.text}</Typography>
            </SignupText>
            <Grid
              item={true}
              xs={6}
              sm={5}
              container={true}
              alignItems='center'
            >
              <ControlledInput
                name='postalCode'
                placeholder='Postcode'
                label='postcode'
                control={control}
                rules={{
                  required: 'Postcode is vereist',
                  pattern: {
                    value: /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i,
                    message: 'Onjuist formaat postcode',
                  },
                }}
                // error={!!errors.postalCode}
                // helperText={
                //   errors && errors.postalCode && errors.postalCode.message
                // }
              />
            </Grid>
            <Grid
              item={true}
              xs={6}
              sm={4}
              container={true}
              alignItems='center'
            >
              <ControlledInput
                name='houseNumber'
                placeholder='Huisnummer'
                label='huisnummer'
                control={control}
                rules={{ required: 'Huisnummer is vereist' }}
              />
            </Grid>
            <Grid
              item={true}
              xs={12}
              sm={3}
              alignItems='center'
              container={true}
            >
              <Button
                fullWidth={true}
                color='primary'
                variant='contained'
                disableElevation={true}
                type='submit'
              >
                {frontmatter.buttonText}
              </Button>
            </Grid>
          </Grid>
        </RequestFormContainer>
      </form>
      {showOfferRequestForm && (
        <OfferRequestForm
          hidden={false}
          postalCode={postalCode}
          houseNumber={houseNumber}
          addition={addition}
          setShowOfferRequestForm={setShowOfferRequestForm}
        />
      )}
      {/* need to add a rendered form so netlify can recognize it */}
      <OfferRequestForm
        hidden={true}
        postalCode=''
        houseNumber=''
        addition=''
        setShowOfferRequestForm={setShowOfferRequestForm}
      />
    </Container>
  );
};

const RequestFormContainer = withStyles(theme => ({
  root: {
    margin: '60px auto 0 auto',
    [theme.breakpoints.down('xs')]: {
      marginTop: '20px',
    },
  },
}))(Grid);

const SignupText = withStyles(theme => ({
  root: {
    marginBottom: theme.spacing(6),
    textAlign: 'center',
  },
}))(Grid);

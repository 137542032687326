import { graphql, PageProps } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

import { PageBySlugQuery } from '../types/graphqlTypes';

import Components from './Components';
import { Layout } from './Layout';

interface IPageDefaultTemplateProps extends PageProps {
  data: PageBySlugQuery;
}

const PageDefaultTemplate: React.FunctionComponent<IPageDefaultTemplateProps> =
  ({ data }) => {
    if (
      !data ||
      !data.markdownRemark ||
      !data.markdownRemark.frontmatter ||
      !data.markdownRemark.frontmatter.sections
    ) {
      return <p>no data</p>;
    }
    const { frontmatter } = data.markdownRemark;
    const { sections } = frontmatter;
    return (
      <>
        <Helmet
          htmlAttributes={{
            lang: 'nl',
          }}
        >
          <title>{frontmatter.title}</title>
          <meta name='description' content={frontmatter.description ?? ''} />
        </Helmet>
        <Layout>
          {/* @ts-ignore TODO: fix this */}
          <Components sections={sections} />
        </Layout>
      </>
    );
  };

export default PageDefaultTemplate;

export const pageQuery = graphql`
  query PageBySlug($urlPath: String!) {
    markdownRemark(frontmatter: { path: { eq: $urlPath } }) {
      id
      html
      frontmatter {
        title
        description
        path
        sections {
          componentId {
            frontmatter {
              altText
              buttonText
              componentId
              images
              leaderText
              logo
              subtext
              title
              type
              text
              blocks {
                image
                title
                text
              }
              reviews {
                text
                avatar
                reviewerName
              }
              contactList {
                text
                link
              }
            }
          }
        }
      }
    }
  }
`;
